<template>
  <div class="wrap">
    <p class="title">幻灯片管理</p>
    <!-- 操作 -->
    <div class="action">
      <a-button type="primary" size="small" @click="showAddEditModal">添加幻灯片</a-button>
    </div>
    <!-- 表格 -->
    <a-table
      :dataSource="dataSource"
      :columns="columns"
      :pagination="{ pageSize: pageSize, current: curPage, total: total }"
      :row-key="record => record.id"
      @change="handlePageChange"
    >
      <template #picture="row">
        <img class="avatar" :src="`data:image/jpg;base64,${row.record.picBase64}`" alt="图片" />
      </template>
      <template #type="row">
        {{ row.record.type ? 'PC' : 'APP' }}
      </template>
      <template #state="row">
        {{ row.record.state ? '显示' : '不显示' }}
      </template>
      <template #action="row">
        <div class="action">
          <span class="edit" @click="showAddEditModal(row)">编辑</span>
          <span class="del" @click="del(row)">删除</span>
        </div>
      </template>
    </a-table>
  </div>
  <add-edit-ppt-modal ref="AddEditPptRef" @addOrUpdateSuccess="search"></add-edit-ppt-modal>
</template>

<script>
import { defineComponent, ref, toRefs, createVNode, reactive, onMounted } from 'vue';
import api from '@/services';
import { Modal, message } from 'ant-design-vue';
import AddEditPptModal from './AddEditPPTModal';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    align: 'center'
  },
  {
    title: '图片',
    dataIndex: 'picBase64',
    align: 'center',
    ellipsis: true,
    slots: {
      customRender: 'picture'
    }
  },
  {
    title: '标题',
    dataIndex: 'lanternTitle',
    align: 'center',
    ellipsis: true
  },
  {
    title: '链接',
    dataIndex: 'link',
    align: 'center',
    ellipsis: true
  },
  {
    title: '备注',
    dataIndex: 'remark',
    align: 'center'
  },
  {
    title: '是否显示',
    dataIndex: 'state',
    align: 'center',
    slots: {
      customRender: 'state'
    }
  },
  {
    title: '权重',
    dataIndex: 'weights',
    align: 'center',
    ellipsis: true
  },
  {
    title: '类型',
    dataIndex: 'type',
    align: 'center',
    slots: {
      customRender: 'type'
    }
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    width: 150,
    align: 'center',
    slots: {
      customRender: 'action'
    }
  }
];
export default defineComponent({
  components: { AddEditPptModal },
  setup() {
    onMounted(() => {
      getPPTList();
    });

    const isEdit = ref(false);
    const visible = ref(false);
    const AddEditPptRef = ref();

    // table 相关变量
    const tableState = reactive({
      pageSize: 5,
      curPage: 1,
      total: 0,
      dataSource: [],
      columns
    });

    // 获取预警列表 table data
    const getPPTList = async () => {
      const params = {
        curPage: tableState.curPage,
        pageSize: tableState.pageSize
      };

      const { data, success } = await api.getPPTList(params);
      if (success) {
        tableState.dataSource = data.records;
        tableState.total = data.total;
      }
    };

    // 显示 新增 / 编辑 弹窗
    const showAddEditModal = row => {
      let rowData;
      if (row.record) {
        isEdit.value = true;
        rowData = row.record;
      } else {
        isEdit.value = false;
      }
      AddEditPptRef.value.showModal(isEdit.value, rowData);
    };

    const handleCancel = () => {
      visible.value = false;
    };

    const handlePageChange = page => {
      tableState.curPage = page.current;
      getPPTList();
    };

    // 删除
    const del = row => {
      Modal.confirm({
        title: '',
        centered: true,
        content: createVNode(
          'div',
          {
            style: 'text-align: center;font-size: 18px;font-weight: bold;'
          },
          `确认要删除吗？`
        ),

        async onOk() {
          let { success } = await api.delPPTById(row.record.id);
          if (success) {
            message.success('删除成功！');
            search();
          }
        }
      });
    };

    const search = () => {
      tableState.curPage = 1;
      getPPTList();
    };

    return {
      visible,
      getPPTList,
      handlePageChange,
      showAddEditModal,
      search,
      del,
      AddEditPptRef,
      handleCancel,
      ...toRefs(tableState)
    };
  }
});
</script>

<style lang="less" scoped>
.wrap {
  padding: 15px 20px;
  flex-wrap: wrap;
  .title {
    font-weight: bold;
    font-size: 20px;
  }
  .action {
    display: flex;
    justify-content: flex-end;
  }
  :deep(.ant-table-wrapper) {
    margin-top: 15px;
    .ant-table {
      .avatar {
        width: 50px;
        height: 50px;
        object-fit: cover;
      }
      .action > span {
        margin-right: 14px;
        display: inline-block;
        padding: 0px 10px;
        border-radius: 3px;
        font-size: 12px;
        height: 25px;
        line-height: 25px;
      }

      .action {
        display: flex;
        span {
          cursor: pointer;
          color: #fff;
        }
        .edit {
          background-color: #007fff;
        }
        .del {
          background-color: #d71345;
        }
      }
    }
  }
}
</style>
